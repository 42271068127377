
import { Component } from "vue-property-decorator";
import { Mixins } from "vue-mixin-decorator";
import GeneralMixin from "@/mixin";
import SupplierStaffList from "@/components/admin/list/SupplierStaffList.vue";
@Component({
  components: { SupplierStaffList }
})
export default class extends Mixins<GeneralMixin>(GeneralMixin) {
  public supplier_id = 0;
  public created() {
    this.supplier_id = Number(this.$route.params.supplier_id);
  }
}
